<script
    lang="ts"
    setup
>
    import type { AppTooltip } from '~/ts/types/app'
    import { WINDOW_SAFE_AREA } from '~/constants/common'

    type Props = AppTooltip

    type Emit = {
        (event: 'opened'): void
        (event: 'closed'): void
    }

    const DEFAULT_INDENT = 16
    const DEFAULT_INDENT_STYLE = DEFAULT_INDENT + 'px'

    const props = withDefaults(defineProps<Props>(), {
        direction: 'up',
        width: undefined,
        offsetY: 0,
        offsetX: 0,
        tailOffsetY: 0,
        tailOffsetX: 0,
        contentClass: undefined,
        disabled: false,
        disableClosing: false,
        closeOnClickOutside: false,
        noWrap: false,
    })
    const emit = defineEmits<Emit>()

    const tooltipRef = ref<ReturnType<typeof defineComponent>>()

    const active = ref<boolean>(false)
    const closeOnBlur = ref<boolean>(false)

    if (props.closeOnClickOutside) {
        onClickOutside(tooltipRef, () => (active.value = false))
    }

    const style = useCssModule()

    const tooltipStyle = computed<string>(() => {
        let style = ''

        if (props.width) {
            style += `--tooltip-width: ${ getSizeValue(props.width) };`
        }

        if (props.offsetY) {
            style += `--tooltip-offset-y: ${ getSizeValue(props.offsetY) };`
        }

        if (props.offsetX) {
            style += `--tooltip-offset-x: ${ getSizeValue(props.offsetX) };`
        }

        if (props.tailOffsetY) {
            style += `--tooltip-tail-offset-y: ${ getSizeValue(props.tailOffsetY) };`
        }

        if (props.tailOffsetX) {
            style += `--tooltip-tail-offset-x: ${ getSizeValue(props.tailOffsetX) };`
        }

        return style || undefined
    })
    const tooltipClass = computed<string[]>(() => [
        style['tooltip'],
        style['tooltip--' + props.direction],
        props.noWrap ? 'whitespace-nowrap' : undefined,
        props.contentClass as string,
    ].filter(Boolean))
    const offsets = computed(() => ({
        x: parseInt(props.offsetX as string) || 0,
        y: parseInt(props.offsetY as string) || 0,
    }))

    const open = (): void => {
        if (props.disabled) {
            return
        }

        active.value = true

        setTimeout(() => emit('opened'), 0)
    }

    const close = (event = undefined): void => {
        if (props.disableClosing) {
            return
        }

        if ('tooltip' in (event?.relatedTarget?.dataset || {})) {
            closeOnBlur.value = true

            return
        }

        active.value = false

        setTimeout(() => emit('closed'), 0)
    }

    const toggle = (event): void => active.value ? close(event) : open()

    const setTooltipPosition = async (el): Promise<void> => {
        // Для коректного оновлення висоти елемента
        await new Promise(resolve => setTimeout(resolve, 0))

        if (!el.previousElementSibling) {
            return
        }

        const activatorRect = el.previousElementSibling.getBoundingClientRect()
        const windowHeight = document.documentElement.clientHeight - WINDOW_SAFE_AREA
        const windowWidth = document.documentElement.clientWidth - WINDOW_SAFE_AREA
        const contentHeight = el.clientHeight
        const contentWidth = el.clientWidth

        let contentTop: number
        let contentLeft: number

        switch (props.direction) {
            case 'up':
                contentTop = Math.round(activatorRect.top - contentHeight - DEFAULT_INDENT)
                contentLeft = Math.round((activatorRect.left + (activatorRect.width / 2)) - (contentWidth / 2))

                el.style.top = contentTop + -offsets.value.y + 'px'
                el.style.left = contentLeft + offsets.value.x + 'px'
                break
            case 'down':
                contentTop = Math.round(activatorRect.top + activatorRect.height + DEFAULT_INDENT)
                contentLeft = Math.round((activatorRect.left + (activatorRect.width / 2)) - (contentWidth / 2))

                el.style.top = contentTop + offsets.value.y + 'px'
                el.style.left = contentLeft + offsets.value.x + 'px'
                break
            case 'left':
                contentTop = Math.round((activatorRect.top + (activatorRect.height / 2)) - (contentHeight / 2))
                contentLeft = Math.round(activatorRect.left - contentWidth - DEFAULT_INDENT)

                el.style.top = contentTop + offsets.value.y + 'px'
                el.style.left = contentLeft + -offsets.value.x + 'px'
                break
            case 'right':
                contentTop = Math.round((activatorRect.top + (activatorRect.height / 2)) - (contentHeight / 2))
                contentLeft = Math.round(activatorRect.left + activatorRect.width + DEFAULT_INDENT)

                el.style.top = contentTop + offsets.value.y + 'px'
                el.style.left = contentLeft + offsets.value.x + 'px'
                break
        }

        /* Корекція позиції */

        const contentRight = windowWidth - (contentLeft + contentWidth)
        const contentBottom = windowHeight - (contentTop + contentHeight)

        if (contentTop < 0) {
            el.style.top = WINDOW_SAFE_AREA + 'px'
        } else if (contentBottom < 0) {
            el.style.top = contentTop + contentBottom + 'px'
        }

        if (contentLeft < 0) {
            el.style.left = WINDOW_SAFE_AREA + 'px'
        } else if (contentRight < 0) {
            el.style.left = contentLeft + contentRight + 'px'
        }

        if (contentHeight > windowHeight) {
            el.style.top = WINDOW_SAFE_AREA + 'px'
            el.style.maxHeight = (windowHeight - (WINDOW_SAFE_AREA * 2)) + 'px'
        }

        if (contentWidth > windowWidth) {
            el.style.left = WINDOW_SAFE_AREA + 'px'
            el.style.maxWidth = (windowWidth - (WINDOW_SAFE_AREA * 2)) + 'px'
        }
    }

    const tooltipBeforeEnter = (el): void => {
        el.style.opacity = 0
    }

    const tooltipEnter = async (el, done): Promise<void> => {
        await setTooltipPosition(el)

        el.style.opacity = 1

        done()
    }

    defineExpose({
        active,
        open,
        close,
        toggle,
    })
</script>

<template>
    <slot
        name="activator"
        :active="active"
        :open="open"
        :close="close"
        :toggle="toggle"
    />

    <Transition
        :css="false"
        @before-enter="tooltipBeforeEnter"
        @enter="tooltipEnter"
    >
        <div
            v-if="active"
            key="tooltip"
            ref="tooltipRef"
            data-tooltip=""
            :class="tooltipClass"
            :style="tooltipStyle"
            @mouseleave.passive="closeOnBlur && close()"
        >
            <slot
                :active="active"
                :close="close"
            />
        </div>
    </Transition>
</template>

<style
    lang="sass"
    module
    scoped
>
    .tooltip
        z-index: 10
        position: fixed
        display: flex
        flex-direction: column
        width: var(--tooltip-width, auto)
        padding: 12px 16px
        font-size: 16px
        font-weight: 400
        background: #000
        color: #fff
        border-radius: 8px

        &::before,
        &::after
            content: ''
            position: absolute

        &::before
            z-index: 1

        &::after
            z-index: -1

        &--up,
        &--down
            &::before
                left: calc(50% - var(--tooltip-tail-offset-x, 0px))
                border-left: 10px solid transparent
                border-right: 10px solid transparent
                transform: translateX(-50%)

            &::after
                left: calc(-1 * var(--tooltip-offset-x, 0px))
                width: calc(100% + var(--tooltip-offset-x, 0px))
                height: calc(100% + v-bind('DEFAULT_INDENT_STYLE') + var(--tooltip-offset-y, 0px))

        &--left,
        &--right
            &::before
                top: calc(50% - var(--tooltip-tail-offset-y, 0px))
                border-top: 10px solid transparent
                border-bottom: 10px solid transparent
                transform: translateY(-50%)

            &::after
                top: calc(-1 * var(--tooltip-offset-y, 0px))
                width: calc(100% + v-bind('DEFAULT_INDENT_STYLE') + var(--tooltip-offset-x, 0px))
                height: calc(100% + var(--tooltip-offset-y, 0px))

        &--up
            &::before
                top: calc(100% - 2px)
                border-top: 10px solid #000

            &::after
                top: 0

        &--down
            &::before
                bottom: calc(100% - 2px)
                border-bottom: 10px solid #000

            &::after
                bottom: 0

        &--left
            &::before
                left: calc(100% - 2px)
                border-left: 10px solid #000

            &::after
                left: 0

        &--right
            &::before
                right: calc(100% - 2px)
                border-right: 10px solid #000

            &::after
                right: 0
</style>
